<script lang="ts">
export default {
  name: 'TheMainNews',
};
</script>

<script setup lang="ts">
import NewsItemComponent from '@/components/News/NewsItemComponent.vue';
import BaseLink from '@/components/ui/BaseLink.vue';
import type { NewsItemClass } from '@/Models/News';
import ArrowRightJaecooIcon from '@/components/icons/ArrowRightJaecooIcon.vue';

export interface ITheMainNewsProps {
  newsItem: NewsItemClass;
}

const theMainNewsProps = defineProps<ITheMainNewsProps>();

const imageSizes = [
  {
    imageWidth: 390,
    screenWidth: 420,
  },
  {
    imageWidth: 736,
    screenWidth: 768,
  },
  {
    imageWidth: 1037,
    screenWidth: 1024,
  },
  {
    imageWidth: 610,
    screenWidth: 1350,
  },
];
</script>

<template>
  <div class="the-main-news">
    <div class="the-main-news__container container">
      <slot name="title">
        <h2 class="page-h2-title h2">Главная тема</h2>
      </slot>
      <div class="the-main-news__news-item">
        <NewsItemComponent
          :key="theMainNewsProps.newsItem.id"
          :newsItem="theMainNewsProps.newsItem"
          class="news-items__item"
          :isHorizontal="true"
          :imageSizes="imageSizes"
          :width="595"
          :height="397"
        >
          <template v-slot:title>
            <a :href="theMainNewsProps.newsItem.linkToNewsItemPage">
              <h4 class="h4 the-main-news__news-item-title">{{ theMainNewsProps.newsItem.title }}</h4>
            </a>
          </template>
          <template v-slot:action-component>
            <BaseLink class="news-item__link" type="button" :href="theMainNewsProps.newsItem.linkToNewsItemPage"
              >Читать далее
              <template v-slot:right-icon>
                <ArrowRightJaecooIcon />
              </template>
            </BaseLink>
          </template>
        </NewsItemComponent>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.the-main-news__news-item-title {
  text-align: left;
}

.the-main-news {
  background-color: $colorBright;

  :deep(.base-image) {
    height: 100%;
    object-fit: cover;
  }
}

.the-main-news__container {
  padding-top: 48px;
}

.the-main-news {
  .news-item__horizontal {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
}

.the-main-news__news-item {
  background-color: $colorWhite;

  .news-item__link {
    margin: 0;
    column-gap: 10px;
    height: fit-content;
    display: flex;
    align-items: center;

    svg {
      margin-left: 9px;
      width: 17px;
      height: 12px;
    }
  }

  :deep(.news-item__content) {
    padding: 48px 60px 60px;
    grid-template-rows: max-content 33px 1fr 62px;

    @include screenEqAndLess1024 {
      padding: 24px 30px 30px;
    }
  }
}
</style>
