<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 7.5C6.72386 7.5 6.5 7.72386 6.5 8C6.5 8.27614 6.72386 8.5 7 8.5L7 7.5ZM29 8.5C29.2761 8.5 29.5 8.27614 29.5 8C29.5 7.72386 29.2761 7.5 29 7.5L29 8.5ZM7 8.5L29 8.5L29 7.5L7 7.5L7 8.5Z"
      fill="#FFFFFF"
    />
    <path
      d="M7 17.5C6.72386 17.5 6.5 17.7239 6.5 18C6.5 18.2761 6.72386 18.5 7 18.5L7 17.5ZM29 18.5C29.2761 18.5 29.5 18.2761 29.5 18C29.5 17.7239 29.2761 17.5 29 17.5L29 18.5ZM7 18.5L29 18.5L29 17.5L7 17.5L7 18.5Z"
      fill="#FFFFFF"
    />
    <path
      d="M7 27.5C6.72386 27.5 6.5 27.7239 6.5 28C6.5 28.2761 6.72386 28.5 7 28.5L7 27.5ZM29 28.5C29.2761 28.5 29.5 28.2761 29.5 28C29.5 27.7239 29.2761 27.5 29 27.5L29 28.5ZM7 28.5L29 28.5L29 27.5L7 27.5L7 28.5Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
