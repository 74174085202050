<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4682 26.284L21.8805 25.4215C20.587 24.8829 20.1562 22.8075 20.1562 22.4029C20.7753 21.8905 21.4496 21.0017 21.4496 20.2469C21.4496 19.492 21.8805 19.3843 21.8805 19.3843C21.8805 19.3843 22.3122 18.3063 22.3122 17.6591C22.3122 17.6591 22.2045 17.2283 21.8805 17.2283V14.6405C21.8805 12.5204 20.8025 12.6999 21.0179 12.4845C21.0179 11.9809 20.4075 10.7593 17.9993 10.7593C15.5473 10.7593 14.1181 13.0948 14.1181 14.6405V17.2283C13.795 17.2283 13.6873 17.6591 13.6873 17.6591C13.6873 18.3063 14.1181 19.3843 14.1181 19.3843C14.1181 19.3843 14.5498 19.492 14.5498 20.2469C14.5498 21.0017 15.2232 21.8905 15.8433 22.4029C15.8433 22.8075 15.4124 24.8829 14.1181 25.4215L11.5312 26.284"
      stroke="#FFFFFF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29 18C29 24.0749 24.0749 29 18 29C11.9251 29 7 24.0749 7 18C7 11.9251 11.9251 7 18 7C24.0749 7 29 11.9251 29 18V18Z"
      stroke="#FFFFFF"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
