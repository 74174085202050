<script lang="ts">
export default {
  name: 'BaseLink',
};
</script>

<script lang="ts" setup>
import { computed } from 'vue';

interface IPressLinkProps {
  href: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  color?: string;
  type?: 'link' | 'button';
  isEmitter?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<IPressLinkProps>(), {
  href: '',
  target: '_self',
  isEmitter: false,
});

const isAbsoluteLink = computed(() => {
  try {
    new URL(props.href);
    return true;
  } catch (err) {
    return false;
  }
});

const typeClass = computed(() => {
  return props.type === 'button' ? 'base-link--button' : '';
});

const colorClass = computed(() => `base-link--color-${props.color}`);
</script>

<template>
  <a
    v-if="isAbsoluteLink"
    :class="[colorClass, typeClass]"
    :disabled="props.disabled ? true : undefined"
    :href="props.href"
    :target="props.target"
    class="base-link"
  >
    <slot></slot>
  </a>
  <router-link v-else-if="props.isEmitter" v-slot="{}" :to="props.href" custom>
    <a :class="[colorClass, typeClass]" class="base-link base-link-router">
      <slot v-bind="$attrs"></slot>
    </a>
  </router-link>
  <router-link v-else :class="[colorClass, typeClass]" :to="props.href" class="base-link base-link-router">
    <slot name="left-icon"></slot>
    <slot></slot>
    <slot name="right-icon"></slot>
  </router-link>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.base-link.router-link-exact-active {
  color: $colorDense !important;
  position: relative;

  &.router-link-exact-active--red-bottom-border:after {
    content: ' ';
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: red;
    bottom: 0;
    z-index: 10;
    right: 0;
  }
}

.base-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
}

.base-link {
  color: $colorDarkBrand;

  &.base-link--color-white {
    color: white;
    
    &.base-link--button {
      border: 1px solid white;
    }
  }
}

.base-link--full-height {
  height: 100%;
}

.base-link--button {
  padding: $buttonVerticalPaddings $buttonHorizontalPaddings;
  line-height: 24px;
  border: 1px $colorButtonJaecoo solid;
  background-color: $colorButtonJaecoo;
  color: $colorWhite !important;

  &[disabled] {
    border-color: $colorLightBrand;
    color: $colorButtonDisableJaecoo;
    background-color: $colorButtonDisableBgJaecoo;
    pointer-events: none;
  }

  @include borderedButtonHover;

  display: block;
  margin: auto;
  width: fit-content;
}
</style>
