<script setup lang="ts">
import { useBasketStore } from '@/stores/basket';

const basketStore = useBasketStore();
</script>

<template>
  <div class="press-cart-icon">
    <div class="press-cart-icon__count-badge" v-if="basketStore.basket?.count && basketStore.basket?.count > 0">
      {{ basketStore.basket.count }}
    </div>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 27C23 25.895 23.895 25 25 25C26.105 25 27 25.895 27 27C27 28.105 26.105 29 25 29C23.895 29 23 28.105 23 27V27Z"
        stroke="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 27C13 25.895 13.895 25 15 25C16.105 25 17 25.895 17 27C17 28.105 16.105 29 15 29C13.895 29 13 28.105 13 27V27Z"
        stroke="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 8H10L12.518 20.588C12.798 21.991 14.029 23 15.459 23H27"
        stroke="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 11H29L28.219 17.248C28.094 18.249 27.243 19 26.234 19H12.5"
        stroke="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.press-cart-icon {
  position: relative;
  height: 36px;
  width: 36px;
}

.press-cart-icon__count-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 13px;
  border-radius: 10px;
  background-color: $colorRed;
  font-size: 9px;
  color: $colorWhite;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
