import type { IMenuItem } from '@/types/Iterfaces';
import ModelsDropdown from '@/components/Header/ModelsDropdown.vue';

export const menuItems: IMenuItem[] = [
  {
    href: '/brand',
    text: 'О бренде',
  },
  {
    href: '/models',
    text: 'Модели',
    footerHref: '/models/j7/',
    footerText: 'JAECOO J7',
    component: ModelsDropdown,
  },
  {
    href: '/news',
    text: 'Новости',
  },
  {
    href: '/gallery',
    text: 'Медиа',
  },
  {
    href: '/contacts',
    text: 'Контакты',
  },
];

export const unauthorisedMenuItems: IMenuItem[] = [
  {
    href: '/',
    text: 'Войти',
  },
  {
    href: '/register',
    text: 'Регистрация',
  },
];

export const footerData = {
  year: `© ${new Date().getFullYear()}`,
  companyName: ' АО «Чери Автомобили Рус»',
  legalInformation: {
    text: 'Правовая информация',
    link: 'https://jaecoo.ru/jaecoo/terms-conditions/',
  },
  socialLinks: [
    {
      name: 'telegram',
      link: 'https://t.me/jaecoorussia',
    },
    {
      name: 'vk',
      link: 'https://vk.com/jaecoorussia',
    },
  ],
  notes: [
    '* Указана максимальная цена перепродажи JAECOO J7 (Джейку Джей 7) комплектации Lifestyle (Лайфстайл) на дату 28.09.2023 без учета дополнительного оборудования или иных услуг. Данная цена указана с учетом скидки дилера в размере 340 000 рублей, которая состоит из скидок: программы Трейд-ин 220 000 рублей и «JAECOO Credit Classic» - 120 000 рублей при покупке нового автомобиля «JAECOO J7» 2023 года выпуска (в комплектации Lifestyle (Лайфстайл)) в кредит.',
    'Под скидкой по программе Трейд-ин понимается единовременная и разовая скидка потребителю на все комплектации от розничной цены автомобиля, приобретаемого по Программе, при сдаче в зачёт его стоимости принадлежащего потребителю любого автомобиля с пробегом. Условия программы уточняйте у официальных дилеров JAECOO.',
    'Генеральная лицензия Банка России № 963 от 05 декабря 2014 года. Банк самостоятельно принимает решение о предоставлении либо отказе в предоставлении кредита без объяснения причин. Информация действительна на 12.04.2023 г. Информация не является офертой (в соответствии со ст. 437, 494 ГК РФ). Подробности уточняйте у официальных дилеров JAECOO, список которых расположен на сайте https://jaecoo.ru/dealers. Представленные на изображениях автомобили могут отличаться от серийных моделей. Указанное в рекламе оборудование может быть опциональным.'
  ],
};

export const subscribePageData = {
  checkboxAppTitle: 'пресс-портала Jaecoo',
  policyPersonalDataLink: 'https://jaecoo.ru/jaecoo/terms-conditions/policy-personal-data/',
};

export const brandPageDataJaecoo = {
  banner_1: {
    title: 'J-SYSTEM',
    subtitle: 'Мой стиль жизни - использовать все возможности',
  },
  banner_2: {
    title: 'J-NATURE',
    subtitle: 'Природа помогает мне раскрыть мои внутренние силы. Я люблю выезжать на природу для активного отдыха, хобби, или просто в компании друзей',
  },
  banner_3: {
    title: 'J-CITY',
    subtitle: 'В городе проходит основная часть моей жизни. Именно здесь я могу жить на полную в разных режимах. Деловые встречи, торговые центры, концерты и фестивали, клубы, кафе и рестораны – я везде чувствую себя естественно и непринужденно',
  },
  banner_4: {
    title: 'J-SPORT',
    subtitle: 'Спорт, физическая активность – это то, что помогает мне продолжать движение. Помогает жить активно и разнообразно',
  },
  banner_5: {
    title: 'J-TRAVEL',
    subtitle: 'Jaecoo – мой лучший навигатор в исследовании мира и его бесконечных возможностей и впечатлений',
  },
  plate_1: {
    title: '',
    text_1:
      'Я умею сбрасывать рамки, установленные социумом. Умею жить по своим законам. Но я не один такой – нас, единомышленников, немало. Нас объединяют те же принципы, которые сформировали и ДНК бренда Jaecoo – J-SYSTEM',
    dark: true,
  },
  plate_2: {
    title: 'Настоящий компаньон',
    text_1:
      'Jaecoo – мой надежный спутник на пути в мир настоящего общения, полезного и приятного взаимодействия',
    dark: false,
  },
  plate_3: {
    title: 'Настоящий помощник',
    text_1:
      'Jaecoo – всегда поможет мне в реализации личных потребностей и интересов, достижении целей',
    dark: false,
  },
  plate_4: {
    title: 'Настоящий партнер',
    text_1:
      'Jaecoo – позволяет мне максимально сконцентрироваться перед тренировкой или соревнованием, а после – расслабиться и заслуженно отдохнуть',
    dark: true,
  },
  plate_5: {
    title: 'Настоящий навигатор',
    text_1:
      'Путешествия – лучший способ раздвинуть границы, наслаждаться реальностью. Я всегда рад отправиться в короткую поездку или в увлекательное путешествие в другие города',
    dark: false,
  },
};

export const brandPageData = {
  banner_1: {
    title: 'JAECOO',
  },
  banner_2: {
    title: 'o-universe',
    subtitle: 'Станьте свидетелями большого технологического взрыва, рождения новой вселенной — O-Universe.',
  },
  banner_3: {
    title: 'o-club',
    subtitle: 'O-People экспериментируют и не боятся рисковать, создают будущее прямо сейчас.',
  },
  banner_4: {
    title: 'o-life',
    subtitle:
      'В нашей новой вселенной больше всего ценится способность находить удивительное в привычных вещах, видеть новые возможности там, где другим это не под силу',
  },
  banner_5: {
    title: 'o-lab',
    subtitle: 'Если вы хотите создавать новую вселенную вместе с JAECOO — следите за анонсами и событиями O-Lab!',
  },
  plate_1: {
    title: 'Будущее начинается вместе с JAECOO',
    subtitle: 'Добро пожаловать в O-Universe — вселенную исключительного дизайна и инновационных технологий.',
    text_1:
      'O-Universe — это новое измерение, в котором возможно опередить привычное время, используя силу конструкторской мысли и технологий последнего поколения. Это мир, где мы получаем уникальный опыт и объединяем свои усилия, чтобы создавать нечто совершенно новое.',
    dark: true,
  },
  plate_2: {
    title: 'Сообщество единомышлен\u00ADников',
    subtitle: '',
    text_1:
      'Их объединяет невероятная энергия, желание создавать, непрерывно совершенствоваться и генерировать новые идеи. Что из них сбудется, мы узнаем совсем скоро — ведь будущее гораздо ближе, чем кажется.',
    dark: false,
  },
  plate_3: {
    title: '',
    subtitle:
      'В авангарде JAECOO — наши амбассадоры, достижения и\u00A0рекорды которых на грани человеческих возможностей, за пределами достижимого.',
    text_1:
      'В числе первых амбассадоров — Анна Щербакова — обладательница золотой медали в одиночном фигурном катании на Олимпийских играх в Пекине, чемпионка мира и трехкратная чемпионка России, совершившая мировой рекорд на чемпионате Европы.',
    dark: false,
  },
  plate_4: {
    title: 'заглянуть за линию горизонта',
    subtitle: 'O-Life — это вещи и события, которые наполняют каждый день, позволяют видеть мир на 360 градусов',
    text_1:
      'O-People пробуют себя в искусстве, музыке, моде, разрушая внутренние барьеры и расширяя свой кругозор. Безлимитный пропуск к незабываемому опыту, который открывается перед владельцами JAECOO и друзьями бренда. Присоединяйтесь к образу жизни O-People и становитесь участниками уникальный событий и активностей, которые специально для вас подготовила команда бренда.',
    dark: true,
  },
  plate_5: {
    title: 'переходите на новый уровень',
    subtitle:
      'Электрические автомобили, беспилотники и искусственный интеллект уже совсем скоро станут нашей реальностью.',
    text_1:
      'O-Lab — это серия онлайн лекций про будущее автомобильной индустрии. В качестве спикеров выступают лучшие автоэксперты, журналисты и блогеры. Вместе с нашим сообществом, мы будем проводить самые смелые инновационные эксперименты, решать загадки вселенной и исследовать фантастический мир будущего.',
    dark: false,
  },
  cards_1: [
    {
      id: 1,
      title: 'O-fashion',
      subtitle: 'Философия O-Universe определяет стремительный, футуристичный и одновременно минималистичный стиль.',
      text_1: 'O-Fashion — это яркие коллаборации с самыми прогрессивными российскими фэшн-дизайнерами, лимитированные капсульные коллекции реальной и цифровой одежды.',
      text_2: 'Первая дизайнерская капсула одежды O-Fashion будет выпущена уже в сентябре 2022 совместно с амбассадором JAECOO Анной Щербаковой.',
      alt: 'O-fashion',
      img: 'card_1',
    },
    {
      id: 2,
      title: 'O-design',
      subtitle: 'Футуризм, авангардный подход к цвету и форме, дерзкие эксперименты с дизайном – в этом вся JAECOO.',
      text_1: 'Дизайн, выдержанный в концепции Art in Motion, «искусство в движении», разработан международной командой дизайнеров под руководством Стива Юма.',
      text_2: 'O-Design выходит за пределы автомобиля и продолжается в лимитированных аксессуарах для владельцев JAECOO, в специально выпущенных брендом NFT и далее — в бесконечности метавселенной.',
      alt: 'O-design',
      img: 'card_2',
    },
    {
      id: 3,
      title: 'O-art',
      subtitle: 'Digital art, метавселенная, NFT меняют парадигмы и демонстрируют, насколько бесконечно человеческое воображение.',
      text_1: 'Но что происходит, когда вселенная JAECOO соединяется с безграничной фантазией художника? Правильно, автомобили превращаются в объект современного искусства.',
      text_2: 'Один из них будет представлен с 13 по 18 сентября 2022 года в Музее Москвы в рамках коллаборации JAECOO и ярмарки молодого современного искусства Blazar.',
      alt: 'O-art',
      img: 'card_3',
    },
  ],
  cards_2: [
    {
      id: 1,
      title: 'o-tech',
      subtitle: 'Технологии трансформируют привычное понятие вождения и владения автомобилем',
      text_1: 'Наше представление об автомобиле и его роли в современной жизни находится на пороге глубоких изменений.  В направлении O-Tech мы расскажем про передовые разработки, которые уже находятся у вас под капотом и обеспечивают удовольствие от вождения, а также покажем, как вывести опыт пользования JAECOO на новый, космический уровень.',
      alt: 'o-tech',
      img: 'card_4',
    },
    {
      id: 2,
      title: 'o-space',
      subtitle: 'Следите за новостями, чтобы «поймать» JAECOO С5 в своем городе!',
      text_1: 'O-Space – пространство, где вы лично сможете увидеть JAECOO C5, испытать эстетическое удовольствие от автомобиля, ощутить силу инженерной и дизайнерской мысли. Именно здесь начинается физическое знакомство с автомобилем – как и блуждающие вселенные, автомобиль будет постоянно перемещаться, оказываясь в совершенно нестандартных локациях по всей России.',
      alt: 'o-space',
      img: 'card_5',
    },
  ],
  imageSizes_cards_1: [
    {
      imageWidth: 420,
      screenWidth: 420,
    },
    {
      imageWidth: 1024,
      screenWidth: 768,
    },
    {
      imageWidth: 420,
      screenWidth: 1024,
    },
  ],
  imageSizes_cards_2: [
    {
      imageWidth: 420,
      screenWidth: 420,
    },
    {
      imageWidth: 1024,
      screenWidth: 768,
    },
    {
      imageWidth: 1024,
      screenWidth: 1024,
    },
  ],
};

export const mainSiteLink = 'https://jaecoo.ru/';
export const clubLink = 'https://t.me/jaecoo5club';
